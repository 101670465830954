import * as React from "react";
import { Layout } from "../layout/layout";

export const HospitalsProviders = () => {
    return (
        <Layout active={"service"}>
            <div>Hospitals & Providers</div>
        </Layout>
    )
}
